/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Éste es un tema del que quería hablar ya hace tiempo, pero siempre lo dejaba para más tarde. Hoy he leído ", React.createElement(_components.a, {
    href: "http://www.cookingideas.es/la-web-2-0-termina-donde-comienzan-las-aplicaciones-20101130.html",
    title: "La Web 2.0 termina donde comienzan las aplicaciones"
  }, "un"), " ", React.createElement(_components.a, {
    href: "http://www.cookingideas.es/la-web-2-0-termina-donde-comienzan-las-aplicaciones-20101130.html",
    title: "La Web 2.0 termina donde comienzan las aplicaciones"
  }, "articulo en Cooking Ideas"), " que aunque en parte tiene razón va en contra de todas mis ideas sobre lo que debería ser la web del mañana."), "\n", React.createElement(_components.p, null, "El uso de Internet se está diversificando y creciendo, debido a el aumento de su uso, las nuevas plataformas para acceder a ellas y la “socialización” de las comunicaciones, pero tenemos que estar atentos, no caigamos en las redes de empresas que nos quieren cerrar las puertas y encerrarnos en su corral, ", React.createElement(_components.a, {
    href: "http://alt1040.com/2010/11/el-creador-de-la-web-critica-a-las-redes-sociales",
    title: "El creador de la Web critica a las redes sociales"
  }, "tal como explicaba el creador de la Web,"), " Tim Berners-Lee, las “redes sociales” son uno de los peligros de la red pero hay muchos más y un claro ejemplo son las tiendas de aplicaciones. Con iPhone se pusieron de moda y a continuación todos la copiaron, es cierto que es una forma muy sencilla de dar poder a los desarrolladores y crear una comunidad de ellos que favorezcan tu empresa, ¿Pero a que precio?, al precio de bordear la Web, eliminar la libertad que los usuarios tenían en ella, por muy beneficioso que ésto sea para las empresas no se puede permitir, no podemos perder el mayor espacio de libertad que ha tenido el ser humano en toda la historia."), "\n", React.createElement(_components.p, null, "¿Entonces, como hacemos esto? Realmente los dos enfoques se pueden unir en uno solo, cojer la libertad de la Web y la facilidad de desarrollo del modelo “App Store”, mediante un enfoque de ", React.createElement(_components.a, {
    href: "https://mozillalabs.com/",
    title: "Mozilla Labs"
  }, "Open Web como el que buscan en mozilla"), " o el proyecto Chrome de Google. Lo que hace falta es el desarrollo de Super-navegadores, que liberen de todas las trabas a los desarrolladores de aplicaciones y la creación de tiendas de aplicaciones Web."), "\n", React.createElement(_components.p, null, "Por suerte aunque ", React.createElement(_components.a, {
    href: "http://www.wired.com/magazine/2010/08/ff_webrip/all/1"
  }, "muchos supuestos entendidos en tecnologia digan lo contrario"), ", ", React.createElement(_components.a, {
    href: "http://alt1040.com/2010/08/la-web-ha-muerto-me-parece-a-mi-que-no"
  }, "la Web aún no ha muerto"), " y le queda mucho por vivir, pero no por ello debemos dejar de mejorarla, para hacerla mas atractiva y mas sencilla."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
